<script>
import Layout from "@/router/layouts/auth";
import { api } from "@/state/services";
import { mapState, mapActions } from "vuex";
import { required, email, minLength } from "vuelidate/lib/validators";
import { checkUsername } from "@/state/validations/register";
import Password from "@/components/widgets/input-password";
import Languages from "@/components/widgets/languages";

export default {
  locales: {
    pt: {
      "Congratulations!": "Parabéns!",
      "Your account has been successfully created.":
        "Sua conta foi criada com sucesso.",
      Sponsor: "Patrocinador",
      "Sponsor is required.": "Patrocinador é obrigatório.",
      Name: "Nome",
      "Name is required.": "Nome é obrigatório.",
      Birthday: "Data de Nascimento",
      "Birthday is required.": "Data de nascimento é obrigatória.",
      "E-mail": "E-mail",
      "E-mail is required.": "E-mail é obrigatório.",
      "This value should be a valid e-mail.":
        "Este valor deve ser um e-mail válido.",
      Cellphone: "Celular",
      "Cellphone is required.": "Celular é obrigatório.",
      Username: "Usuário",
      "Username is required.": "Usuário é obrigatório.",
      Password: "Senha",
      Register: "Cadastrar",
      "Click here to login": "Clique aqui para fazer o login",
      "Already have an account?": "Já possui uma conta?",
      Login: "Login",
      "The sponsor was not found.": "O patrocinador não foi encontrado.",
      "The username is already in use.": "O usuário já está em uso.",
      "Fill in all required fields.": "Preencha todos os campos obrigatórios.",
      "Please enter a valid username! Words like gmail, hotmail, yahoo or other email services are not allowed.":
        "Digite um nome de usuário válido! Não é permitido palavras como gmail, hotmail, yahoo ou outros serviços de e-mail.",
      "Age must be over 18 years.": "A idade deve ser maior que 18 anos.",
    },
    es: {
      "Congratulations!": "¡Felicidades!",
      "Your account has been successfully created.":
        "Tu cuenta ha sido creada satisfactoriamente.",
      Sponsor: "Patrocinador",
      "Sponsor is required.": "Se requiere patrocinador.",
      Name: "Nombre",
      "Name is required.": "Se requiere el nombre.",
      Birthday: "Fecha de Nacimiento",
      "Birthday is required.": "Fecha de nacimiento é obrigatória.",
      "E-mail": "E-mail",
      "E-mail is required.": "Se requiere correo electrónico.",
      "This value should be a valid e-mail.":
        "Este valor debe ser un correo electrónico válido.",
      Cellphone: "Celular",
      "Cellphone is required.": "Se requiere celular.",
      Username: "Nombre de Usuario",
      "Username is required.": "Se requiere nombre de usuario.",
      Password: "Contraseña",
      Register: "Registro",
      "Click here to login": "Haga clic aquí para ingresar",
      "Already have an account?": "¿Ya tienes una cuenta?",
      Login: "Iniciar Sesión",
      "The sponsor was not found.": "El patrocinador no fue encontrado.",
      "The username is already in use.": "El nombre de usuario ya está en uso.",
      "Fill in all required fields.": "Rellene todos los campos obligatorios.",
      "Please enter a valid username! Words like gmail, hotmail, yahoo or other email services are not allowed.":
        "Digite um nome de usuário válido! Não é permitido palavras como gmail, hotmail, yahoo ou outros serviços de e-mail.",
      "Age must be over 18 years.": "La edad debe ser mayor de 18 años.",
    },
  },
  components: {
    Layout,
    Languages,
    Password,
  },
  data() {
    return {
      loading: {
        plans: true,
        register: false,
        contract: false,
      },
      success: false,
      plans: {},
      country: {
        abbr: "BR",
        code: "55",
        name: "Brazil",
        mask: "+55.###############",
      },
      countries: {},
      sponsor: {
        status: false,
        name: "",
        username: "",
        avatar: "",
      },
      contract: {
        title: "",
        content: "",
        status: false,
      },
      user: {
        plan: {
          slug: "",
          name: "",
          value: "",
        },
        country: "BR",
        sponsor: "",
        name: "",
        birthday: "",
        email: "",
        cellphone: "",
        username: "",
        password: "",
        term: false,
      },
      whatsapp: {
        status: 'empty',
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  validations: {
    user: {
      country: {},
      sponsor: { required },
      name: { required },
      email: { required, email },
      cellphone: { required },
      username: { required, checkUsername },
      password: { required, minLength: minLength(6) },
      term: { required },
    },
  },
  methods: {
    ...mapActions("account", ["register"]),
    getCountries() {
      api.get("country").then((response) => {
        if (response.data.status == "success") {
          this.countries = response.data.list;
        }
      });
    },
    getSponsor() {
      if (this.user.sponsor != this.sponsor.username) {
        this.sponsor.name = "";
        this.sponsor.username = this.user.sponsor;
        this.sponsor.avatar = "";

        api
          .post("user/sponsor", {
            username: this.user.sponsor,
          })
          .then((response) => {
            if (response.data.status == "error") {
              this.sponsor.status = false;
              this.sponsor.name = "";
              this.sponsor.avatar = "";

              if (this.user.sponsor) {
                this.$router.push("/register");
              }
            } else {
              this.sponsor.status = true;
              this.sponsor.name = response.data.user.name;
              this.sponsor.username = response.data.user.username;
              this.sponsor.avatar = response.data.user.avatar;

              localStorage.sponsor = JSON.stringify(response.data.user);

              this.$router.push("/register/" + response.data.user.username);
            }
          });
      }
    },
    getContract() {
      api.get("user/contract").then((response) => {
        if (response.data.status == "success") {
          this.loading.contract = true;
          this.contract.status = response.data.contract.status;
          this.contract.title = response.data.contract.title;
          this.contract.content = response.data.contract.content;
        }
      });
    },
    registerSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const { name, email, cellphone, username, password } = this.user;
        if (name && email && cellphone && username && password) {
          this.loading.register = true;
          api
            .post("user", {
              sponsor: this.user.sponsor,
              name: this.user.name,
              email: this.user.email,
              cellphone: this.user.cellphone,
              username: this.user.username,
              password: this.user.password,
            })
            .then((response) => {
              if (response.data.status == "success") {
                this.user.sponsor   = "";
                this.user.name      = "";
                this.user.email     = "";
                this.user.cellphone = "";
                this.user.username  = "";
                this.user.password  = "";
                this.$v.$reset();

                this.alert.type = "alert-success";
                this.alert.message = response.data.message;

                this.success = true;
              } else {
                this.alert.type = "alert-danger";
                this.alert.message = response.data.message;
              }

              this.loading.register = false;
            });
        }
      }
    },
    checkWhatsapp() {
      api.post("whatsapp/check", {
        number: this.user.cellphone
      }).then((response) => {
        this.whatsapp.status = response.data.status;
      });
    }
  },
  mounted() {
    if (this.$route.params.sponsor) {
      this.user.sponsor = this.$route.params.sponsor;

      api
        .post("user/sponsor", {
          username: this.user.sponsor,
        })
        .then((response) => {
          if (response.data.status == "error") {
            this.sponsor.status = false;
            this.sponsor.name = "";
            this.sponsor.avatar = "";
            this.$router.push("/register");
          } else {
            this.sponsor.status = true;
            this.sponsor.name = response.data.user.name;
            this.sponsor.username = response.data.user.username;
            this.sponsor.avatar = response.data.user.avatar;

            localStorage.sponsor = JSON.stringify(response.data.user);
          }
        });
    }

    if (!this.user.sponsor) {
      if (localStorage.sponsor) {
        this.user.sponsor = JSON.parse(localStorage.sponsor).username;
        this.getSponsor();
      }
    }

    this.getCountries();
    this.getContract();
  },
  watch: {
    "user.country": function (value) {
      this.country = this.countries[value];
      this.country.mask = "+" + this.countries[value].code + ".###############";
    },
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="d-flex">
      <div class="login-content">
        <div class="pt-5 text-center">
          <router-link class="align-middle" tag="a" to="/">
            <img
              class="w-100"
              style="max-width: 200px"
              src="@/assets/images/logo.png"
            />
          </router-link>
        </div>
        <div class="py-5">
          <div v-if="success" class="text-center pt-4 pb-4">
            <h5>
              {{ t("Congratulations!") }}<br /><br />{{
                t("Your account has been successfully created.")
              }}
            </h5>
            <p class="text-dark">
              <router-link tag="a" to="/login" class="btn btn-default">{{
                t("Click here to login")
              }}</router-link>
            </p>
          </div>
          <div v-else>
            <div v-if="alert.message" :class="'alert ' + alert.type">
              {{ t(alert.message) }}
            </div>
            <b-form @submit.prevent="registerSubmit">
              <b-form-group id="sponsor-group" :label="t('Sponsor')" label-for="sponsor">
                <b-form-input v-if="!sponsor.status" id="sponsor" v-model="user.sponsor" type="text" value="" :class="{ 'is-invalid': $v.user.sponsor.$error }" v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'" v-on:blur="getSponsor()"></b-form-input>
                <div v-if="!$v.user.sponsor.required" class="invalid-feedback">{{ t('Sponsor is required.' )}}</div>
                <div v-if="sponsor.status && sponsor.name" class="d-flex my-2 p-3 bg-soft-dark rounded">
                  <div class="mr-2 align-self-center">
                    <img
                      v-if="sponsor.avatar"
                      class="rounded-circle header-profile-user"
                      :src="sponsor.avatar"
                      alt=""
                    />
                    <img
                      v-else
                      class="rounded-circle header-profile-user"
                      src="@/assets/images/logo-icon.png"
                      alt=""
                    />
                  </div>
                  <div class="flex-fill align-self-center">
                    <span class="text-uppercase">{{ sponsor.name }}</span>
                  </div>
                </div>
                <div v-else-if="!sponsor.status && user.sponsor" class=" my-2 p-3 bg-soft-danger rounded">
                  O patrocinador não foi encontrado ou ainda pode estar inativo.
                </div>
              </b-form-group>

              <div class="divisor mb-3">
                <span>Dados Pessoais</span>
              </div>

              <b-form-group id="name-group" :label="t('Name')" label-for="name">
                <b-form-input
                  id="name"
                  v-model="user.name"
                  type="text"
                  :class="{ 'is-invalid': $v.user.name.$error }"
                ></b-form-input>
                <div v-if="!$v.user.name.required" class="invalid-feedback">
                  {{ t("Name is required.") }}
                </div>
              </b-form-group>

              <b-form-group
                id="email-group"
                :label="t('E-mail')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="user.email"
                  type="email"
                  :class="{ 'is-invalid': $v.user.email.$error }"
                ></b-form-input>
                <div v-if="$v.user.email.$error" class="invalid-feedback">
                  <span v-if="!$v.user.email.required">{{
                    t("E-mail is required.")
                  }}</span>
                  <span v-if="!$v.user.email.email">{{
                    t("This value should be a valid e-mail.")
                  }}</span>
                </div>
              </b-form-group>
              <b-form-group
                id="cellphone-group"
                :label="t('Cellphone')"
                label-for="cellphone"
              >
                <select v-model="user.country" class="custom-select mb-1">
                  <option
                    v-for="(option, index) in countries"
                    :key="index"
                    :value="option.abbr"
                    :selected="option.abbr == 'BR'"
                  >
                    {{ option.abbr + " " + option.name + " +" + option.code }}
                  </option>
                </select>
                <b-input-group
                  :prepend="country.abbr + ' +' + country.code"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    id="cellphone"
                    v-model="user.cellphone"
                    type="text"
                    :class="{ 'is-invalid': $v.user.cellphone.$error }"
                    v-mask="country.mask"
                    v-on:blur="checkWhatsapp()"
                  ></b-form-input>
                </b-input-group>
                <div
                  v-if="!$v.user.cellphone.required"
                  class="invalid-feedback"
                >
                  {{ t("Cellphone is required.") }}
                </div>
                <div
                  v-if="whatsapp.status == 'inactive'"
                  class="invalid-feedback d-block text-warning d-flex pt-2 px-2 align-items-center"
                >
                  <div class="mr-2">
                    <i class="lnr lnr-warning font-size-24"></i>
                  </div>
                  <div class="font-size-12">
                    <strong>Atenção: </strong>Informe sempre um número válido de Whatsapp para que os doadores entrem em contato com você.
                  </div>
                </div>
              </b-form-group>

              <div class="divisor mb-3">
                <span>Dados de Acesso</span>
              </div>

              <b-form-group
                id="username-group"
                :label="t('Username')"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="user.username"
                  type="text"
                  :class="{ 'is-invalid': $v.user.username.$error }"
                  v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
                ></b-form-input>
                <div v-if="!$v.user.username.required" class="invalid-feedback">
                  {{ t("Username is required.") }}
                </div>
                <div
                  v-if="!$v.user.username.checkUsername"
                  class="invalid-feedback"
                >
                  {{
                    t(
                      "Please enter a valid username! Words like gmail, hotmail, yahoo or other email services are not allowed."
                    )
                  }}
                </div>
              </b-form-group>
              <b-form-group
                id="password-group"
                :label="t('Password')"
                label-for="password"
              >
                <Password
                  v-model="user.password"
                  :v="$v.user.password"
                ></Password>
              </b-form-group>
              <div v-if="loading.contract && contract.status == 1">
                <div
                  v-html="contract.content"
                  class="border p-3 overflow-auto"
                  style="height: 200px"
                ></div>
                <div class="pt-3">
                  <label>
                    <input
                      type="checkbox"
                      v-model="user.term"
                      :class="{ 'is-invalid': $v.user.term.$error }"
                    />
                    {{ contract.title }}
                  </label>
                </div>
              </div>
              <div class="mt-4">
                <b-button
                  :disabled="
                    loading.register == true ||
                    (!user.term && contract.status == 1)
                  "
                  type="submit"
                  variant="default"
                  class="btn-lg btn-block"
                >
                  {{ t("Register") }}
                  <b-spinner
                    v-if="loading.register"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
          <div class="mt-4">
            <p class="text-dark text-center">
              {{ t("Already have an account?") }}
              <router-link
                tag="a"
                to="/login"
                class="font-weight-medium text-muted"
                >{{ t("Login") }}</router-link
              >
            </p>
            <div class="text-center">
              <Languages type="dropup"></Languages>
            </div>
            <div v-if="sponsor.status && sponsor.name" class="mb-4 text-center">
              <span class="text-uppercase font-size-11 text-muted"
                ><small class="notranslate">REF. {{ sponsor.username }}</small></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex-fill login-image d-none d-lg-block"></div>
    </div>
  </Layout>
</template>

<style scoped>
.label-description {
  margin: -10px 0 10px 0;
}
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}
</style>
